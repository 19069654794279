<script>
import BVForm from '@/components/BVForm/BVForm.vue';
import Swal from 'sweetalert2';


export default
    {
        components: {
            BVForm
        },
        name: 'CargarDocumentosDeSeleccion',
        data() {
            return {
                etapas: [],
                documentosExistentes: [],
                schemasEvalucion: [],
                estados: [],
                schemaEvaluacionSeleccion: [
                    {
                        name: 'SeleccionEvaluacionId',
                        label: 'Evaluación final del proceso de selección',
                        type: 'select',
                        options: [],
                        value: null,
                    }
                ]
            }
        },
        async mounted() {
            await this.getEtapas();
            await this.getEstados();
            await this.getDocumentos();
        },
        methods:
        {
            async nextStepOnSuccess() {
                await this.getDocumentos();
            },
            async getEtapas() {
                const response = await this.$store.getters.fetchGet({ path: `ReclutamientoYSeleccion/Seleccion/etapas/${this.$route.params.vacanteId}` });
                const etapas = await response.json();
                this.etapas = etapas;
                console.log("Estapas", etapas);
            },
            async getEstados() {
                const response = await this.$store.getters.fetchGet({ path: 'ReclutamientoYSeleccion/Seleccion/estados' })
                this.estados = await response.json()
                this.schemaEvaluacionSeleccion[0].options = JSON.parse(JSON.stringify(this.estados))

                console.log(this.estados);

            },
            async getDocumentos() {
                const response = await this.$store.getters.fetchGet({ path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${this.$route.params.vacanteId}/postulacion/${this.$route.params.postulacionId}` })
                this.documentosExistentes = await response.json()

                this.etapas.forEach(etapa => {
                    const doc = this.getByName(etapa.nombre)
                    
                    let newSchema = [
                        {
                            label: etapa.nombre,
                            value: doc ? doc.estado.id : null,
                            name: 'EstadoId',
                            type: 'select',
                            options: JSON.parse(JSON.stringify(this.estados)),
                            validations: 'required'
                        }
                    ]

                    if( etapa.esCuantificable != 0 )
                    {
                        newSchema.push({
                            label: 'Puntaje',
                            name: 'puntaje',
                            value: doc ? doc.puntaje : null,
                            type: 'number',
                            validations: 'required'
                        })
                    }

                    if (etapa.tipo.jsString == null)
                    {
                        newSchema.push({
                            label: 'Observaciones',
                            name: 'descripcion',
                            value: doc ? doc.descripcion : null,
                            type: 'text',
                            validations: ''
                        })
                    }
                    
                    this.schemasEvalucion.push(newSchema)
                })

            },
            documentosInjection(requerimiento) {
                return {
                    vacanteId: this.$route.params.vacanteId,
                    postulacionId: this.$route.params.postulacionId,
                    etapaTipoId: requerimiento.tipo.id,
                    nombreIdEtapas: requerimiento.nombre,
                }
            },
            isDocumentExist(nombre) {
                return this.documentosExistentes.filter(element => element.nombreIdEtapas == nombre).length > 0;
            },
            getByName(nombre) {
                return this.documentosExistentes
                    .find(element => element.nombreIdEtapas == nombre)
            },
            isEvaluated(nombre) {

                if (this.getByName(nombre)) {
                    return this.getByName(nombre).estado.estado != 'Pendiente';
                }
                else {
                    return false;
                }
            },
            async postEstado(self) {
                const data = { ...self.data }
                console.log(data);
                self.loader = true

                const responseGet = await this.$store.getters.fetchGet({
                    path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${data.vacanteId}/postulacion/${data.postulacionId}/nombreEtapa/${data.nombreIdEtapas}`,
                })
                const resultGet = await responseGet.json()

                // console.log(responseGet.ok);
                // console.log(resultGet);

                let responseEstado

                if (resultGet.length) {
                    responseEstado = await this.$store.getters.fetchPut({
                        path: `ReclutamientoYSeleccion/Seleccion/documentos/estado/${resultGet[0].id}`,
                        data
                    })
                }
                else {

                    responseEstado = await this.$store.getters.fetchPost({
                        path: `ReclutamientoYSeleccion/Seleccion/documentos/admin`,
                        data
                    })
                }

                if (responseEstado.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Evaluado',
                        showConfirmButton: true,
                    })
                }
                else {
                    Swal.fire({
                        icon: 'Error',
                        title: 'Inténtalo nuevamente',
                        showConfirmButton: true,
                    })
                }

                this.getDocumentos()
                self.loader = false

            },
            EvaluarProcesoDeSeleccion(self) {
                const data = { ...self.data }

                self.loader = true

                this.$store.getters.fetchPut({
                    path: `Postulacion/ProcesoDeSeleccion/${this.$route.params.postulacionId}`,
                    data
                })
                    .then(response => {
                        if (response.ok) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Proceso de selección finalizado',
                                showConfirmButton: true,
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'Error',
                                title: 'Inténtalo nuevamente',
                                showConfirmButton: true,
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        self.loader = false
                    })
            }
        },
        computed:
        {
            requerimientos() {
                return this.etapas.sort((a, b) => {
                    if (a.tipo.jsString == null) return 1;
                    if (b.tipo.jsString == null) return -1;
                    return a.tipo.jsString.localeCompare(b.tipo.jsString);
                });
            },
            isTodoEvaluado() {
                let documentList = this.documentosExistentes.map(item => ({ nombre: item.nombreIdEtapas, estado: item.estado.estado}))
                let etapasList = this.etapas.map(item => item.nombre)
                return etapasList.every(item => documentList.some(element => element.nombre == item && element.estado != 'Pendiente'))
            }

        }
    }
</script>
<template>
    <div>
        <div class="container-fluid">
            <card class="pr-3">
                <template v-slot:headerTitle>
                    <div class="d-flex">
                        <div class="mr-2">
                            <router-link :to="{ name: 'AdministradorDeVacantes' }">
                                <i class="fas fa-arrow-left fa-lg px-1 text-primary"></i>
                            </router-link>
                        </div>
                        <h4 class="card-title"><b>Requerimientos del proceso de selección</b></h4>
                    </div>
                </template>
                <div v-for="(etapa, idx) in requerimientos" :key="etapa.id" class="my-3">
                    <!-- <div class="mb-3" v-if="etapa.tipo.jsString != null"> -->
                    <div class="mb-3">
                        <h3 class="title">{{ etapa.nombre }}</h3>
                        <div v-if="isDocumentExist(etapa.nombre)">
                            <p class="subTitle">
                                Ya existe un documento cargado
                                <a target="_blank" :href="getByName(etapa.nombre).urlImg">Documento</a>
                            </p>

                            <p>
                                El/La {{ etapa.nombre }} está
                                {{ getByName(etapa.nombre).estado.estado }}
                                <span v-if="getByName(etapa.nombre).estado.estado == 'Aprobado'">
                                    <i class="fas fa-check-circle text-success"></i>
                                </span>
                                <span v-else-if="getByName(etapa.nombre).estado.estado == 'Rechazado'">
                                    <i class="fas fa-times-circle text-danger"></i>
                                </span>
                                <span v-else>
                                    <i class="fas fa-clock text-warning"></i>
                                </span>
                            </p>
                        </div>
                        <BVForm :schema="schemasEvalucion[idx]" :injectData="{
                            vacanteId: $route.params.vacanteId,
                            postulacionId: $route.params.postulacionId,
                            nombreIdEtapas: etapa.nombre,
                            etapaTipoId: etapa.tipo.id
                        }" :callBackSubmit="postEstado"></BVForm>
                    </div>
                </div>

                <BVForm :schema="schemaEvaluacionSeleccion" :callBackSubmit="EvaluarProcesoDeSeleccion"
                    :disabled="!isTodoEvaluado"></BVForm>
            </card>
        </div>
    </div>
</template>
<style scoped>
.title {
    font-size: 1.4rem;
}

.subTitle {
    font-size: 1.2rem;

}
</style>